/* RESET */
button,
input,
textarea,
ul {
	padding: unset;
}
button,
input,
textarea {
	border: unset;
	border-radius: unset;
}
body,
figure,
h1,
h2,
h3,
p,
ul {
	margin: unset;
}
button,
h1,
h2,
h3,
input,
textarea {
	font: unset;
}
abbr[title],
:link,
:visited {
	text-decoration: unset;
}
:link,
:visited {
	color: unset;
}
button,
input,
textarea {
	background-color: unset;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}
/* STYLES */
:root {
	font: 100%/1.5 "Source Sans Pro", sans-serif;
	text-rendering: optimizeLegibility;
	@media (min-width: 1024px) {
		font-size: 120%;
	}
	@media (min-width: 1920px) {
		font-size: 140%;
	}
	.-replay_button {
		padding: 0.25em 0.5em;
		font-weight: 700;
		font-size: 0.75em;
		color: white;
		background-color: black;
		cursor: pointer;
		opacity: 1.00;
		transition-property: opacity;
		transition-duration: 0.2s;
		animation-name: fade-in;
		animation-duration: 0.8s;
		@keyframes fade-in {
			from {
				opacity: 0.00;
			}
			to {
				opacity: 1.00;
			}
		}
		&:focus {
			outline: unset;
		}
		&:focus,
		&:hover {
			opacity: 0.75;
		}
	}
}
.site {
	display: flex;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	@media (orientation: portrait) {
		flex-direction: column;
	}
	@media (orientation: landscape) {
		flex-direction: row;
	}
	@media (orientation: landscape) and (min-height: 768px) {
		flex-direction: column;
	}
	.site-header {
		display: grid;
		box-sizing: border-box;
		flex: 0 0 auto;
		width: 100%;
		max-width: 36em;
		padding: 0.75rem 1rem;
		margin: {
			right: auto;
			left: auto;
		}
		grid-template-columns: auto 1fr;
		@media (orientation: landscape) {
			width: unset;
			grid-template-columns: unset;
			grid-template-rows: 1fr auto;
		}
		@media (orientation: landscape) and (min-height: 768px) {
			width: 100%;
			grid-template-columns: auto 1fr;
			grid-template-rows: unset;
		}
		@media (min-width: 768px) {
			padding: 1.5rem 2rem;
		}
		h1 {
			font: 600 2em/1.5 "Source Serif Pro", serif;
			@media (min-width: 1024px) and (min-height: 768px) {
				font-size: 3em;
			}
		}
		ul {
			display: grid;
			grid-auto-flow: column;
			grid-gap: 1em;
			line-height: 1;
			list-style: none;
			font-size: 0.75em;
			@media (min-width: 1024px) and (min-height: 768px) {
				font-size: unset;
			}
		}
		:link,
		:visited {
			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
		.site-header-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			@media (orientation: landscape) {
				grid-row: 1;
				writing-mode: vertical-rl;
				transform: rotate(180deg);
			}
			@media (orientation: landscape) and (min-height: 768px) {
				grid-row: unset;
				writing-mode: unset;
				transform: unset;
			}
		}
		.site-header-media {
			box-sizing: border-box;
			width: unset;
			height: calc(2em * 1.5 + 1.5em);
			@media (orientation: landscape) {
				grid-row: 2;
				transform: rotate(270deg);
			}
			@media (orientation: landscape) and (min-height: 768px) {
				grid-row: unset;
				transform: unset;
			}
			@media (min-width: 1024px) and (min-height: 768px) {
				height: calc(3em * 1.5 + 1.5em);
			}
		}
	}
	.site-main {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		background-color: #F5F5F5;
		@media (orientation: landscape) {
			grid-row: span 2;
		}
	}
}
.page {
	display: flex;
	flex: 1 0 auto;
	position: relative;
	flex-direction: column;
	--accent-color-primary: hsl(210, 70%, 50%);
	--accent-color-secondary: hsl(180, 70%, 50%);
	.page-header {
		box-sizing: border-box;
		padding: 1.5em 1em;
		position: sticky;
		top: 0;
		z-index: 400;
		color: white;
		background-image: linear-gradient(to bottom right, var(--accent-color-primary), var(--accent-color-secondary));
		@media (min-width: 768px) {
			padding: {
				right: 2rem;
				left: 2rem;
			}
		}
		h2 {
			font: 600 1.5em/1.5 "Source Serif Pro", serif;
			@media (min-width: 768px) {
				font-size: 2em;
			}
		}
		.-meta-urls {
			display: flex;
			margin-bottom: 0.75em;
			flex-direction: column;
			align-items: flex-start;
			font-size: 0.75em;
			list-style: none;
			opacity: 1.00;
			@media (min-width: 768px) {
				font-size: unset;
			}
			img {
				display: inline-block;
				width: 1em;
				height: 1.25em;
				margin-right: 0.25em;
				vertical-align: top;
				@media (min-width: 768px) {
					margin-left: -1.25em;
				}
			}
			:link,
			:visited {
				&:focus,
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.-meta-skills,
		.-meta-tech {
			display: flex;
			font-weight: 300;
			font-size: 0.75em;
			opacity: 0.75;
			list-style: none;
			> * {
				&::after {
					content: ",\A0";
				}
				&:last-child {
					&::after {
						content: unset;
					}
				}
			}
		}
		.-meta-credit {
			font-size: 0.75em;
			:link,
			:visited {
				font-weight: 600;
				&:focus,
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.page-header-content {
			max-width: calc(36em - 2em);
			margin: {
				right: auto;
				left: auto;
			}
			@media (min-width: 768px) {
				max-width: calc(36em - 4em);
			}
		}
		&[data-credit="hype"] {
			.page-header-content {
				background-image: url("/assets/hype-logo.svg");
				background-position: right 0.7em bottom;
				background-repeat: no-repeat;
				background-size: auto 2em;
			}
		}
		&.-animated {
			transition-property: height, padding-top, padding-bottom;
			transition-duration: 0.2s;
			h2 {
				transition-property: font-size;
				transition-duration: 0.2s;
			}
			.-meta-skills,
			.-meta-tech,
			.-meta-urls {
				transition-property: opacity;
				transition-duration: 0.2s;
			}
			&[data-credit="hype"] {
				.page-header-content {
					transition-property: background-position;
					transition-duration: 0.2s;
				}
			}
		}
		&.-collapsed {
			height: calc(0.75em + 1.5em + 0.75em) !important;
			padding: {
				top: 0.75em;
				bottom: 0.75em;
			}
			overflow-y: hidden;
			@media (min-width: 768px) {
				height: calc(0.75em + 1.5em * 1.5 + 0.75em) !important;
			}
			h2 {
				font-size: 1em;
				@media (min-width: 768px) {
					font-size: 1.5em;
				}
			}
			.-meta-skills,
			.-meta-tech,
			.-meta-urls {
				opacity: 0.00;
			}
			&[data-credit="hype"] {
				.page-header-content {
					background-position: right 0.7em top;
					@media (min-width: 768px) {
						background-position: right 0.7em top 0.5em;
					}
				}
			}
		}
	}
	.page-main {
		flex-grow: 1;
		font-weight: 300;
		background-color: white;
		.-replay_button {
			background-color: var(--accent-color-primary);
		}
		figure {
			margin-bottom: 1.5em;
			grid-column: 2;
			@media (min-width: 768px) {
				display: grid;
				grid-column: span 3;
				grid-template-columns: 2em auto 2em;
			}
			img,
			picture,
			video {
				@media (min-width: 768px) {
					grid-column: span 3;
				}
			}
		}
		figcaption {
			margin-top: 0.75em;
			text-align: center;
			font-style: oblique;
			@media (min-width: 768px) {
				grid-column: 2;
			}
		}
		form,
		p,
		ol,
		ul {
			margin-bottom: 1.5em;
			grid-column: 2;
		}
		img,
		picture,
		video {
			display: block;
			width: auto;
			height: auto;
			max-width: 100%;
			margin: auto;
		}
		strong {
			font-weight: 400;
		}
		:link,
		:visited {
			font-weight: normal;
			color: var(--accent-color-primary);
			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
		.page-main-content {
			display: grid;
			max-width: 36em;
			padding-top: 1.5em;
			margin: {
				right: auto;
				left: auto;
			}
			grid-template-columns: 1em auto 1em;
			@media (min-width: 768px) {
				grid-template-columns: 2em auto 2em;
			}
		}
	}
	.page-footer {
		padding: 1.5em 1em;
		color: white;
		background-image: linear-gradient(to bottom right, var(--accent-color-primary), var(--accent-color-secondary));
		@media (min-width: 768px) {
			padding: {
				right: 2rem;
				left: 2rem;
			}
		}
		h3 {
			font: 600 1.25em/1.5 "Source Serif Pro", serif;
			margin-bottom: 0.75em;
		}
		.-button {
			display: inline-block;
			padding: 0.25em 1em;
			color: var(--accent-color-primary);
			background-color: white;
			transition-property: background-color;
			transition-duration: 0.2s;
			&:focus,
			&:hover {
				background: rgba(255, 255, 255, 0.75);
			}
			&:active {
				background: rgba(255, 255, 255, 0.50);
			}
		}
		.-tray {
			display: flex;
			> * {
				margin-right: 1em;
				&:last-child {
					margin-right: unset;
				}
			}
		}
		.page-footer-content {
			max-width: calc(36em - 2em);
			margin: {
				right: auto;
				left: auto;
			}
			@media (min-width: 768px) {
				max-width: calc(36em - 4em);
			}
		}
	}
}
.feature {
	display: contents;
	@media (orientation: landscape), (min-width: 768px) {
		display: grid;
		grid-template-columns: 1em 2fr 1em 3fr 1em;
		grid-auto-flow: column;
		grid-column: span 3;
		margin-bottom: 1.5em;
	}
	@media (min-width: 768px) {
		grid-template-columns: 2em 2fr 1em 3fr 2em;
	}
	.feature-media {
		display: block;
		width: 100%;
		height: auto;
		margin-bottom: 0.75em;
		grid-column: 2;
		@media (orientation: landscape), (min-width: 768px) {
			grid-column: span 2;
			margin: auto;
		}
	}
	.feature-content {
		margin-bottom: 1.5em;
		@media (orientation: landscape), (min-width: 768px) {
			grid-column: 2 / span 3;
			margin-bottom: unset;
		}
	}
	.feature-media + .feature-content {
		@media (orientation: landscape), (min-width: 768px) {
			grid-column: 4;
			margin: auto;
		}
	}
	.feature-header {
		margin-bottom: 0.75em;
		h3 {
			font: 600 1.25em/1.5 "Source Serif Pro", serif;
		}
	}
	.feature-main {
		p,
		ol,
		ul {
			margin-bottom: 1.5em;
			&:last-child {
				margin-bottom: unset;
			}
		}
	}
	> * {
		grid-column: 2;
	}
	&:nth-of-type(even) {
		@media (orientation: landscape) {
			grid-template-columns: 1em 3fr 1em 2fr 1em;
		}
		@media (min-width: 768px) {
			grid-template-columns: 2em 3fr 1em 2fr 2em;
		}
		.feature-media {
			@media (orientation: landscape), (min-width: 768px) {
				grid-column: 4 / span 2;
			}
		}
		.feature-media + .feature-content {
			@media (orientation: landscape), (min-width: 768px) {
				grid-column: 2;
			}
		}
	}
}
